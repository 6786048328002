import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { nodeName } from "jquery"
import Img from "gatsby-image"
const Header = props => {
  const spaceData = props.data.allListings.edges
  const data = spaceData.filter(
    dat =>
      dat.node.geoLocation[0] === props.filter.geoLocation &&
      dat.node.slug !== props.filter.slug
  )

  return (
    <React.Fragment>
      {data.length ? (
        <div className="col-md-12">
          <h4>Related Spaces</h4>
        </div>
      ) : (
        ""
      )}
      {data.map((space, index) => {
        return (
          index < 3 && (
            <div className="col-md-6">
              <br></br>
              <div className="BlogCardMini" key={space.node.slug}>
                <Link to={"/meeting-space/" + space.node.slug}>
                  <h4 className="BlogTitle" title={space.node.OriginalName}>
                    {space.node.spaceDisplayName} - {space.node.OriginalName}
                  </h4>
                  <div></div>
                  <br></br>
                  <Link
                    className="relatedSpaceButton"
                    to={"/meeting-space/" + space.node.slug}
                  >
                    More Details
                  </Link>
                  <br></br>
                </Link>
                <br></br>
              </div>
            </div>
          )
        )
      })}
    </React.Fragment>
  )
}

//return(<h1></h1>)

const RelatedSpaces = props => {
  return (
    <StaticQuery
      query={graphql`
        {
          allListings(filter: { spaceType: { eq: "Conference Room" } }) {
            totalCount
            edges {
              node {
                monthPassAvailable
                dayPassAvailable
                hourPassAvailable
                officeSpaceType
                purposesList
                spaceAddress
                spaceGFName
                OriginalName
                spaceCity
                spaceId
                spaceImage
                photos
                spaceTitle
                spaceDesc
                spaceType
                subType
                priceDay
                priceHr
                priceMonth
                spaceDisplayName
                Facility
                slug
                hasCovidSafeBadge
                online
                Rating
                pincode
                bookingcount
                isFeatured
                priceperdayPreDiscount
                priceperhrPreDiscount
                operatorName
                googleMapsLink
                googleRating
                googleReviewCount
                avgRating
                geoLocation
                seat
                spaceFeedback {
                  feedback
                  rating
                }
              }
            }
          }
        }
      `}
      render={data => <Header data={data} filter={props.filter} />}
    />
  )
}

export default RelatedSpaces
